import { AppBar } from '@mui/material';
import React, { Component }  from 'react';

export default class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      props: props
    };
  }

  render() {
    return (
      <AppBar {...this.state.props}>
        {{...this.state.props}.children}
      </AppBar>
    );
  }
}
