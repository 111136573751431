import { Container } from '@mui/material';
import React, { Component }  from 'react';

export default class Content extends Component {

  constructor(props) {
    super(props);
    this.state = {
      props: props
    };
    console.log(this.state.props);
  }

  render() {
    return (
      <Container {...this.state.props}>
        {{...this.state.props}.children}
      </Container>
    );
  }
}
