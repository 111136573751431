import React, { Component, useEffect } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

class Login extends Component {

  render() {
    return
  }
}

export default (props) => {
  useEffect(() => {
    console.log(props);
    window.location.assign(`${process.env.REACT_APP_AUTH_URL}/login?redirect=${process.env.REACT_APP_URL}`);
  }, []);

  return <Login {...props} />;
}
