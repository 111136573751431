import React, { Component }  from 'react';
import { Container, Typography, Divider, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';

export default class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      props: props
    };
  }

  render() {
    if (this.state.props.children) {
      return (
        <Container {...this.state.props}>
          {this.state.props.children}
        </Container>
      );
    }
    return (
      <Container {...this.state.props}>
        <Paper>
            <Divider style={{ background: '#424242' }} />
            <Table>
              {/* <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6" component={'p'}>
                      About
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" component={'p'}>
                      Contact
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead> */}
              <TableBody>
                { /* <TableRow>
                  <TableCell>
                    <Typography variant="body2" component={'p'}>
                      Adornapps
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" component={'p'}>
                      Contact us
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" component={'p'}>
                      Copyright
                    </Typography>
                  </TableCell>
                  <TableCell rowSpan={2}>
                    {<Typography variant="body2" component={'p'}>
                      Calle Real 105<br />
                      Castilleja de la Cuesta, Sevilla<br />
                      Spain 41950
                    </Typography>}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" component={'p'}>
                      Terms of Service
                    </Typography>
                  </TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell colSpan={2}>
                    <Typography variant='body1' component={'p'}>
                      Copyright &copy; {new Date().getFullYear()} {process.env.REACT_APP_NAME}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
      </Container>
    );
  }
}
