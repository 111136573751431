import './App.css';
import React, { Component } from 'react';
import { Content, Footer, Navbar } from '../../components';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Container, Typography, Link, Divider } from '@mui/material';
import { Button, Toolbar, } from '@mui/material';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      navbarHeight: 0
    };
  }

  componentDidMount() {
    // Get the navbar
    var navbar = document.getElementById("navbar");
    // Get navbar height
    var navbarHeight = navbar.offsetHeight;
    this.setState({ navbarHeight: navbarHeight }, () => {
      console.log(this.state.navbarHeight);
    });
  }

  render() {
    return (
      <Container {...this.props} maxWidth={false} disableGutters className='App' sx={{ display: 'flex' }} >
        <Navbar sx={{ background: '#fafafa', color: '#0e0e0e', position: 'sticky' }} id="navbar" >
          <Toolbar>
            <Link href={ process.env.REACT_APP_URL }>
              <img src="/favicon.ico" style={{ height: '2.25em', width: 'auto', }} alt={ process.env.REACT_APP_NAME } />
            </Link>
            <Container maxWidth={false} sx={{ flexGrow: 1, justifyContent: 'flex-end', display: 'flex', margin: 0 }} >
              {/* <Button href={`${process.env.REACT_APP_URL}/login`} color="inherit">Login</Button> */}
            </Container>
          </Toolbar>
        </Navbar>
        <Content disableGutters maxWidth={false} sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'strech', flexWrap: 'no-wrap' }}>
          <Container maxWidth={false} sx={{ background: "rgb(0, 207, 179)", padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', color: "#0e0e0e", flexBasis: `calc(100vh - ${this.state.navbarHeight}px)` }}>
            <Typography variant="h2">
              We are
            </Typography>
            <Typography variant="h1" fontFamily={'ubuntu'}>
              {process.env.REACT_APP_NAME}
            </Typography>
          </Container>
          <Divider variant="middle" style={{ background: '#424242' }} />
          { /* TODO: Connect this to a DB and make it dynamic */ }
          <Container maxWidth={false} sx={{ background: "", padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', color: "#0e0e0e", flex: 0, flexBasis: '50vh' }}>
            <Typography variant="h3" component={'p'}>
              We make
            </Typography>
            <Typography variant="h2" fontFamily={'ubuntu'} component={'p'}>
              Applications
            </Typography>
            <Typography variant="h1" component={'p'}>
              for <i>you</i>
            </Typography>
          </Container>
          <Divider variant="middle" style={{ background: '#424242' }} />
          <Container maxWidth={false} sx={{ background: "", padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', color: "#0e0e0e", flex: 0, flexBasis: '60vh' }}>
            <Typography variant="h2" component={'p'}>
              We make
            </Typography>
            <Link variant="h1" fontFamily={'ubuntu'} component={'a'} href='https://additiveapp.adornapps.com/'>
              AdditiveApp
            </Link>
          </Container>
          {/* <Divider variant="middle" style={{ background: '#424242' }} />
          <Container maxWidth={false} sx={{ background: "", padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', color: "#0e0e0e", flex: 0, flexBasis: '40vh' }}>
            <Typography variant="h2" component={'p'}>
              We make
            </Typography>
            <Link variant="h1" fontFamily={'ubuntu'} href='https://link-r.app/'>
              Link-R
            </Link>
          </Container> */}
        </Content>
        <Footer disableGutters maxWidth={false} /* sx={{ flexBasis: '20vh' }} */ />
      </Container>
    );
  }
}

export default App;
