import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import React from 'react';
import { App, Login } from './views';

export default function AppRouter (props) {
    const navigate = useNavigate();

    return (
        <Routes>
            <Route path="/" element={<App style={{"--cursor-you-img": `url(${process.env.REACT_APP_URL}/you.svg), auto`}} />} />
            <Route path="/login" element={<Login navigate={navigate}/> } />
            <Route path="/privacy-policy" element={
                <div>
                    <p>In this privacy policy, we will explain how we collect, use, and protect your personal information. This can be applied to all AdornaApps Aplications such AdditiveApp. We will also explain your rights and how you can contact us if you have any questions or concerns.</p>

                    <h2>What information do we collect?</h2>
                    <p>We collect your device ID and aproximative location based on your IP, also certain usage data such as the time and date of your visit, the pages that you viewed, and the time spent on those pages.</p>

                    <h2>How do we use your information?</h2>
                    <p>We use your information to provide you with the best possible experience by analysing anonymous data in order to improve the app</p>

                    <h2>How do we protect your information?</h2>
                    <p>We take the security of your personal information very seriously. We have implemented appropriate technical and organisational measures to protect your personal information against unauthorised or unlawful processing and against accidental loss, destruction or damage. We also encrypt this information to prevent unauthorised access.</p>
                    <p>However, we encourage you to take appropriate precautions to protect your personal information, such as keeping your device secure and not sharing your device ID with others.</p>
                    <p>We do not collect any personal information, only device ID and aproximative location based on your IP.</p>

                    <h2>Do we share your information with third parties?</h2>
                    <p>No. We are concerned about the actual problem of unmeasured data sharing and we do not share your information with third parties.</p>

                    <h2>Assistance about this privacy policy</h2>
                    <p>If you have any questions or concerns about our privacy policy, please contact us at
                        <a href="mailto:contact@adornapps.com">contact@adornapps.com</a>
                    </p>

                    <h2>Changes to this privacy policy</h2>
                    <p>We may update our privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page.</p>

                    <h2>Consent</h2>
                    <p>By using our services, you hereby consent to our privacy policy and agree to its terms.</p>

                    <h2>Effective date</h2>
                    <p>This privacy policy was last updated on 2021-06-01.</p>
                </div>
            } />
            <Route path="*" element={<p>404 -&gt; TBD </p>} />
        </Routes>
    )
}